import styled from "styled-components";

export const Button = styled.button`
  border-radius: 5px;
  background: ${({ primary }) => (primary ? "#921F37" : "#2899ff")};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "14px 48px" : "12px 30px")};
  color: ${({ dark }) => (dark ? "#010606" : "#fff")};
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "21px")};
  font-family: "Bebas Neue", cursive !important;
  letter-spacing: 1px;
  outline: none;
  border: none;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  &.active {
      background-color: #921F37;
  }

  &:hover {
      background-color: #1c6bb2;
  }
`;